import axios from '../libs/ajaxRequest';

//获取店铺banner
const getBanner = (token) => {
    return axios.request({
        url: 'Products/getShopBanner',
        method: 'post',
        data: {
            token
        }
    })
};

//获取产品分类
const getTypes = (token) => {
    return axios.request({
        url: 'Products/getTypes',
        method: 'post',
        data: {
            token
        }
    })
};

//获取产品列表
const getProduct = (data) => {
    return axios.request({
        url: 'products/getProducts',
        method: 'post',
        data
    })
};

//获取产品备注分类
const getRemarkTypes = (token) => {
    return axios.request({
        url: 'Products/getRemarkTypes',
        method: 'post',
        data: {
            token
        }
    })
};

//添加收藏
const addFav = (data) => {
    return axios.request({
        url: 'products/AddFav',
        method: 'post',
        data
    })
};

//取消收藏
const removeFav = (data) => {
    return axios.request({
        url: 'products/RemoveFav',
        method: 'post',
        data
    })
};

//获取收藏夹列表
const getFavList = (token) => {
    return axios.request({
        url: 'Products/getFavList',
        method: 'post',
        data: {
            token
        }
    })
};

//获取员工信息
const getEmpInfo = (token) => {
    return axios.request({
        url: 'Employee/getEmpInfo',
        method: 'post',
        data: {
            token
        }
    })
};

//获取产品详情
const getProductDetail = (data) => {
    return axios.request({
        url: 'products/getProductDetail',
        method: 'post',
        data
    })
};

//获取收藏夹备注
const getFavRemark = (data) => {
    return axios.request({
        url: 'Products/getFavRemark',
        method: 'post',
        data
    })
};

//更新产品备注
const updateFav = (data) => {
    return axios.request({
        url: 'products/updateFav',
        method: 'post',
        data
    })
};

//上传员工足迹
const footPrint = (data) => {
    return axios.request({
        url: 'footPrint',
        method: 'post',
        data
    })
};

export{
    getBanner,
    getTypes,
    getProduct,
    getRemarkTypes,
    addFav,
    removeFav,
    getFavList,
    getEmpInfo,
    getProductDetail,
    getFavRemark,
    updateFav,
    footPrint
}